@font-face {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 300;
    src: local('Helvetica Light'), local('Helvetica-Light'), url("./fonts/Helvetica-Light.ttf");
}

@font-face {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    src: local('Helvetica'), local('Helvetica-Regular')
}

@font-face {
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 700;
    src: local('Helvetica Bold'), local('Helvetica-Bold')
}