body {
    margin: 0;
    font-family: Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color:transparent;
    -moz-tap-highlight-color:transparent;
    -o-tap-highlight-color:transparent;
    tap-highlight-color:transparent;
}

img[src=""] {
    opacity: 0;
}

img:not([src=""]) {
    opacity: 1;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
